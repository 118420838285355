<template>
  <a-card :bordered="false">
    <div class="card-title">{{ title }}<span v-if="queryParam.position">（{{ queryParam.position}}）</span></div>
    <a-spin :spinning="loading">
      <div class="table-operator">
        <!--        <a-button v-action:add type="primary" @click="handleAdd">添加轮胎记录</a-button>-->
        <!--          <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>-->
      </div>
      <s-table
          ref="table"
          rowKey="vt_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:450}"
      >
        <div slot="tire_type" slot-scope="text, item">
          <a-tag v-if="text==0" color="blue">补/调胎</a-tag>
          <a-tag v-else color="green">新胎更换</a-tag>
        </div>
        <div slot="project" slot-scope="text, item">
          <a>{{ text }}</a>
        </div>
        <div slot="kilometers" slot-scope="text, item">
          {{ text ? text + 'km' : '' }}
        </div>
        <div slot="source" slot-scope="text, item">
          {{ text == 1 ? '后台' : '小程序' }}
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:edit @click="handleEdit(item)">编辑</a>
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
    </a-spin>
  </a-card>
</template>
<script>
import * as Api from '@/api/vehicle/vehicleTire'
import {STable} from "@/components";
import pred from "@/views/vehicle/index/pre/Index.vue";

export default {
  props: ['vehicle_id'],
  name: "ShowToolList",
  components: {pred, STable},
  data() {
    return {
      title: '轮胎记录',
      queryParam: {
        vehicle_id: this.vehicle_id,
        position: '',
      },
      visible: false,
      loading: false,
      isLoading: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'vt_id',
          align: 'center',
          width: 60,
        },
        {
          title: '类型',
          dataIndex: 'tire_type',
          align: 'center',
          width: 60,
          scopedSlots: {customRender: 'tire_type'}
        },
        {
          title: '更换位置',
          dataIndex: 'position',
          align: 'center',
        },
        {
          title: '公里数',
          dataIndex: 'kilometers',
          align: 'center',
          scopedSlots: {customRender: 'kilometers'}
        },
        {
          title: '上传地点',
          dataIndex: 'address',
          align: 'center',
        },
        {
          title: '提交时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        //
        // {
        //   title: '来源',
        //   dataIndex: 'source',
        //   align: 'center',
        //   scopedSlots: {customRender: 'source'},
        //   width: 60,
        // },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'}
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(vehicle_id, vehicle_name) {
      this.title = vehicle_name + ' - 轮胎记录'
    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDetails() {

    },

    handleDelete(item) {
      Api.deleted({vt_id: item['vt_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
