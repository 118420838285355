<template>
  <div>
    <a-row :gutter="10">
      <a-col :span="14">
        <a-alert
            type="info"
            message="温馨提示"
            description="点击车辆红色圆点区域可显示对应的轮胎，维修，保养，加油记录等"
            banner
        />
        <a-spin :spinning="spinning" tip="Loading...">
          <div id="container">
            <div class="select">
              <a-select
                  style="width: 200px"
                  allowClear
                  @change="handleChange"
                  show-search
                  :filter-option="filterOption"
                  v-model="vehicle_id"
                  placeholder="请选择车辆"
              >
                <a-select-option v-for="item in vehicle_list" :value="item.vehicle_id" :item="item">
                  {{ item.vehicle_name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </a-spin>
      </a-col>
      <a-col :span="10">

        <template v-if="mode=='ct'">
          <maintenanceLog ref="maintenanceLog" :vehicle_id="vehicle_id" :title="name"/>
          <weixiuLog style="margin-top:10px" ref="weixiuLog" :vehicle_id="vehicle_id" :title="name"/>
        </template>

        <template v-if="mode=='yx'">
          <refuelLog ref="refuelLog" :vehicle_id="vehicle_id" :title="name"/>
        </template>
        <!--        <ureaLog ref="ureaLog" :vehicle_id="vehicle_id" :title="name"/>-->

        <template v-if="mode=='lt'">
          <tireLog ref="tireLog" :vehicle_id="vehicle_id" :title="name"/>
        </template>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import tireLog from './lt.vue'
import refuelLog from './jy.vue'
import ureaLog from './urea'
import maintenanceLog from './maintenanceLog/Index'
import weixiuLog from './weixiuLog/Index'
import * as vehicleApi from "@/api/vehicle";

export default {
  components: {
    refuelLog,
    tireLog,
    ureaLog,
    maintenanceLog,
    weixiuLog,
  },
  data() {
    return {
      name: '',
      clickName: '',
      text: '',

      mode: '',
      vehicle_id: Number(this.$route.query.vehicle_id),
      vehicle_name: this.$route.query.vehicle_name,
      vehicle_list: [],
      spinning: true
    }
  },
  created() {
    this.getVehicleList()
    this.getList('车辆信息', 'ct')
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.init()
        this.spinning = false
      }, 500)
    })
  },
  methods: {
    getVehicleList() {
      vehicleApi.getSelectList().then(({data: {list}}) => {
        this.vehicle_list = list
      })
    },
    init() {
      const that = this
      const param = {
        appkey: 'coqyKLsOWZvP',
        appsecret: '2dwzDhVYJns04S9LMHrWujPQpBalvZkx'
      }
      const viewer = new AMRT.Viewer('container', /*容器dom或者dom对应的id*/ param)
      viewer.loadModel('1751443271672008704', {
        onLoad: () => {

          // viewer.container.onclick = function (e) {
          //   //通过鼠标拾取模型对象
          //   var p = viewer.picker.screenToVector(e)
          //   if (p) {
          //     const element = document.createElement('div')
          //     element.innerText = '热点'
          //     element.className = 'customadc'
          //     viewer.hotSpotManager.add({
          //       position: p, //热点放置位置(必填)
          //       cameraView: viewer.controls.getView(), //点击热点后需要回到的视角(可选)(可通过viewer.controls.getView获取当前视角)
          //       element   //自定义热点的dom元素
          //     })
          //   }
          // }

          // viewer.container.addEventListener('click', e => {
          //   //通过鼠标拾取模型对象
          //   var object = viewer.picker.intersectObject(e)
          //   if (object) {
          //     console.log(object)
          //   }
          // })


          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": -0.5969810919812469,
              "y": 0.3018856239379917,
              "z": 6.127183185953273
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            // element: '车头',
            // cameraView: viewer.controls.getView(),
            // layer: layer_1,
            onclick(label) {//点击事件
              that.getList('车头', 'ct')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": -1.6792003418111792,
              "y": 0.5133823471197321,
              "z": 4.094272971572566
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('一轴右', 'lt', '1轴右')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": -1.7036519336235443,
              "y": 0.5438916240277034,
              "z": 1.8778873854259674
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('二轴右', 'lt', '2轴右')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": -1.7034376976379342,
              "y": 0.45157959442054896,
              "z": 0.729149360450454
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('三轴右', 'lt', '3轴右')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": -1.7037793076153855,
              "y": 0.5151420727504223,
              "z": -3.5266812572590727
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('四轴右', 'lt', '4轴右')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": -1.7036764364729056,
              "y": 0.5352989783048283,
              "z": -4.646566048733707
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('五轴右', 'lt', '5轴右')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": -1.703437612374419,
              "y": 0.5465757785500538,
              "z": -5.673980054645452
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('六轴右', 'lt', '6轴右')
            }
          })

          //左边
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": 0.5286083814180706,
              "y": 0.6111250473435452,
              "z": 4.21783987213414
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('一轴左', 'lt', '1轴左')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": 0.5601446710291811,
              "y": 0.5872337610351039,
              "z": 1.922590818666429
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              console.log(label)
              that.getList('二轴左', 'lt', '2轴左')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": 0.5603195407138828,
              "y": 0.5599826805720449,
              "z": 0.843386470314448
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('三轴左', 'lt', '3轴左')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": 0.5600273271643383,
              "y": 0.5328267843088457,
              "z": -3.5470694573473085
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('四轴左', 'lt', '4轴左')
            }
          })
          // viewer.hotSpotManager.addSpriteLabel({
          //   position: {
          //     "x": 0.4675929947156736,
          //     "y": 0.37919421359600647,
          //     "z": -3.284155711416881
          //   },
          //   content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
          //   onclick(label) {//点击事件
          //     that.getList('5轴左','5l1')
          //   }
          // })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": 0.5600341030353826,
              "y": 0.5151964588596207,
              "z": -4.653779706711145
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('五轴左', 'lt', '5轴左')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": 0.5600381286238894,
              "y": 0.5441775328802914,
              "z": -5.631846435655224
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('六轴左', 'lt', '6轴左')
            }
          })
          viewer.hotSpotManager.addSpriteLabel({
            position: {
              "x": 0.3484054598163224,
              "y": 1.0840426175578282,
              "z": 3.452545816112757
            },
            content: '<div style="width:15px;height:15px;background: red;border: 1px solid #fff;border-radius: 90px"></div>',
            onclick(label) {//点击事件
              that.getList('油箱', 'yx')
            }
          })

        }
      })

    },
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange(e) {
      this.vehicle_id = e
      this.handleRefresh()
    },
    getList(name, type, text = '') {
      this.mode = type
      this.clickName = name
      this.text = text


      this.handleRefresh()
      // if (type == 'yx') {
      //   this.name = this.vehicle_name + '（' + name + '-加油记录' + '）'
      // } else {
      //   this.name = this.vehicle_name + '（' + name + '-轮胎记录' + '）'
      //   this.$refs.lt.handleRefresh()
      // }
    },
    handleRefresh() {
      const item = this.vehicle_list.find((item) => {
        return item.vehicle_id == this.vehicle_id
      })
      this.vehicle_name = item.vehicle_name
      this.name = this.vehicle_name + this.clickName

      if (this.mode == 'ct') {
        this.$refs.maintenanceLog.queryParam.vehicle_id = this.vehicle_id
        this.$refs.maintenanceLog.handleRefresh(true)
        this.$refs.weixiuLog.queryParam.vehicle_id = this.vehicle_id
        this.$refs.weixiuLog.handleRefresh(true)
      } else if (this.mode == 'yx') {
        this.$refs.refuelLog.queryParam.vehicle_id = this.vehicle_id
        this.$refs.refuelLog.handleRefresh(true)
      } else if (this.mode == 'lt') {
        this.$refs.tireLog.queryParam.vehicle_id = this.vehicle_id
        this.$refs.tireLog.queryParam.position = this.text
        this.$refs.tireLog.handleRefresh(true)
      }
    }
  }
}
</script>
<style scoped lang="less">
@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}

.customadc {
  width: 10px;
  height: 10px;
  background: #777;
  border: 1px solid #fff;
  border-radius: 90px
}

#container {
  width: 100%;
  height: calc(100vh - 190px);
  position: relative;

  .select {
    position: absolute;
    top: 10px;
    left: 10px
  }
}
</style>
